<template>
  <div class="pipeline-row" :class="{ 'out-of-report-row': isOutOfReportRow }">
    <div class="drag-handle" v-if="order === ''"></div>
    <CustomCheckbox
      :isCheckboxChecked="isCheckboxChecked"
      @click="handleCheckboxClick"
    />
    <EditableField
      v-if="name && isNameEditable"
      :value="name"
      :isSubscriptionActive="isSubscriptionActive"
      @valueUpdated="$emit('updateName', $event)"
    />
    <div v-if="name && !isNameEditable" class="pipeline-row-text-filed">
      {{ name }}
    </div>
    <EditableField
      v-if="company && isCompanyEditable"
      :value="company"
      :isSubscriptionActive="isSubscriptionActive"
      @valueUpdated="$emit('updateCompany', $event)"
    />
    <div v-if="company && !isCompanyEditable" class="pipeline-row-text-filed">
      {{ company }}
    </div>
    <EditableField
      :value="dealValue"
      :isDisabled="isEditableFieldDisabled"
      :isSubscriptionActive="isSubscriptionActive"
      :isOnlyNumeric="true"
      @lockSwitcher="handleSwitcherLock"
      @unlockSwitcher="handleSwitcherUnlock"
      @valueUpdated="handleDealValueUpdate"
    />
    <div class="pipeline-row-note-field">
      <TableNoteField :value="note" @pipelineNoteUpdated="handleNoteUpdate" />
    </div>
    <div class="action-btn-container">
      <MoreActionsBtn
        :width="'32px'"
        :height="'32px'"
        :isActive="isActionMenuShown"
        @click="switchActionMenuState"
      />
      <ActionMenu
        :width="actionMenuWidth"
        :height="actionMenuHeight"
        :top="actionMenuTop"
        :isActionMenuShown="isActionMenuShown"
        @closeActionMenu="closeActionMenu"
      >
        <ActionMenuItem
          v-for="(action, index) in menuActions"
          :key="index"
          :text="action"
          @actionMenuItemClick="handleActionMenuItemClick(action)"
        />
      </ActionMenu>
    </div>
  </div>
</template>

<script>
import CustomCheckbox from '../../common/CustomCheckbox.vue'
import EditableField from '../../common/EditableField.vue'
import MoreActionsBtn from '../../common/MoreActionsBtn.vue'
import ActionMenu from '../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../common/actionMenu/ActionMenuItem.vue'
import CustomSwitcher from '../../common/mainContent/CustomSwitcher.vue'
import TableNoteField from '../../common/mainContent/TableNoteField.vue'
import { updateNoteValue } from '../../../api/pipelinesApi'

export default {
  props: [
    'id',
    'order',
    'note',
    'selectedRows',
    'isNameEditable',
    'isCompanyEditable',
    'name',
    'company',
    'dealValue',
    'menuActions',
    'actionMenuWidth',
    'actionMenuHeight',
    'actionMenuTop',
    'isSubscriptionActive',
    'isOutOfReportRow',
    'subjectID',
  ],
  components: {
    CustomCheckbox,
    EditableField,
    MoreActionsBtn,
    ActionMenu,
    ActionMenuItem,
    CustomSwitcher,
    TableNoteField,
  },
  data() {
    return {
      isCheckboxChecked: false,
      isActionMenuShown: false,
      isSwitcherChecked: false,
      isSwitcherLocked: false,
    }
  },
  created() {
    this.isCheckboxChecked = this.selectedRows.includes(this.id)
  },
  watch: {
    selectedRows(newValue, _) {
      if (newValue.includes(this.id)) this.isCheckboxChecked = true
      else this.isCheckboxChecked = false
    },
  },
  computed: {
    isEditableFieldDisabled() {
      return !this.isSubscriptionActive
    },
  },
  methods: {
    handleCheckboxClick() {
      if (this.isCheckboxChecked) {
        this.isCheckboxChecked = false
        this.$emit('removeSelection')
      } else {
        this.isCheckboxChecked = true
        this.$emit('checkboxSelect')
      }
    },
    handleDealValueUpdate($event) {
      this.$emit('updateDealValue', $event)
    },
    handleSwitcherLock() {
      this.isSwitcherLocked = true
    },
    handleSwitcherUnlock() {
      this.isSwitcherLocked = false
    },
    handleSwitcherClick(evt) {
      if (this.isSwitcherLocked || !this.isSubscriptionActive) return
      this.$emit('changeDealClosedState')
    },
    switchActionMenuState() {
      if (!this.isSubscriptionActive) return
      if (this.isActionMenuShown) this.isActionMenuShown = false
      else this.isActionMenuShown = true
    },
    closeActionMenu() {
      this.isActionMenuShown = false
    },
    handleActionMenuItemClick(action) {
      this.isActionMenuShown = false
      this.$emit('actionMenuItemClick', action)
    },
    async handleNoteUpdate(newValue) {
      if (newValue !== this.note) {
        await updateNoteValue(this.subjectID, newValue)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.pipeline-row {
  width: calc(100% - 80px);
  height: 44px;
  position: relative;
  padding: 0 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeStandart;
  color: $fontColorBlack;

  .drag-handle {
    width: 16px;
    height: 6px;
    position: absolute;
    top: calc(50% - 3px);
    left: 13px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    background-image: url('../../../assets/icons/drag-handle-icon.svg');
    cursor: grab;
  }

  .pipeline-row-text-filed {
    width: 150px;
    min-width: 150px;
    height: 20px;
    margin-left: 30px;
    display: block;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }

  .pipeline-row-note-field {
    width: 100%;
    min-width: 250px;
    height: 20px;
    margin-left: 30px;
    display: block;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }

  .switcher-container {
    width: 90px;
    min-width: 90px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action-btn-container {
    width: 42px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    .more-actions-btn {
      border: none;
    }

    .more-actions-btn-active-state {
      border: 1px solid $grayBorderColor;
    }
  }
}

.out-of-report-row {
  color: $fontColorGray !important;
}

@media (max-width: 1024px) {
  .pipeline-row {
    width: calc(100% - 98px) !important;

    .pipeline-row-text-filed {
      min-width: 100px;
    }
  }
}

@media (max-width: 420px) {
  .pipeline-row {
    .pipeline-row-text-filed {
      min-width: 95px;
      margin-left: 45px;
    }

    .editable-field {
      min-width: 75px;
      margin-left: 40px;

      .input-field {
        min-width: 100px;
      }
    }

    .action-btn-container {
      margin-left: 30px;
    }
  }
}
</style>
